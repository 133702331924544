
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { heartOutline, personOutline, chatboxEllipsesOutline, informationCircleOutline, logOut} from 'ionicons/icons';
import { mapActions } from "vuex"
import {store} from '@/store';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'explore',
        url: '/explore',
        iosIcon: heartOutline,
        mdIcon: heartOutline
      },
      {
        title: 'matches',
        url: '/matches',
        iosIcon: chatboxEllipsesOutline,
        mdIcon: chatboxEllipsesOutline
      },
      {
        title: 'profile',
        url: '/profile/edit',
        iosIcon: personOutline,
        mdIcon: personOutline
      },
      // {
      //   title: 'imprint',
      //   url: '/imprint',
      //   iosIcon: informationCircleOutline,
      //   mdIcon: informationCircleOutline
      // }
    ];
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
    const router = useRouter();

    return { 
      selectedIndex,
      appPages, 
      labels,
      router,
      heartOutline, 
      personOutline, 
      chatboxEllipsesOutline, 
      informationCircleOutline,
      logOut,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  },
  data() {
    return {
      deviceToken: ''
    }
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    async logoutCustomer() {
      await store.dispatch("auth/signOut");
      // await this.signOut(this.deviceToken);
      this.router.go('/login')

    }
  }
});
