export default {
  "routes": {
    "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecken"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matches"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finde deinen PawPal!"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Profil anlegen"])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Profil"])},
    "form": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Adresse"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
      "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
      "remove_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel (z.B \"Lisa und Bello\")"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibe dich/euch in eigenen Worten"])},
      "characteristic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenschaften"])},
      "searchingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "take_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])}
    }
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
  },
  "characteristics": {
    "cuddly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschmust"])},
    "playful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verspielt"])},
    "intelligent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelligent"])}
  },
  "searchingFor": {
    "paw_pal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gassi Kumpel"])},
    "dog_walker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gassi Service"])},
    "dog_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hundebesitzer"])}
  },
  "chat": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "message_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Nachricht"])}
  },
  "imprint": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum..."])}
  },
  "success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion erfolgreich"])},
    "messages": {
      "updating_profile_successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil wurde gespeichert"])},
      "match_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Match wurde bestätigt! Ihr könnt jetzt miteinander Chatten."])}
    }
  },
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten"])},
    "messages": {
      "login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login fehlgeschlagen"])},
      "no_data_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine weiteren Nutzer gefunden :("])},
      "updating_profile_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil konnte nicht gespeichert werden."])}
    }
  },
  "report": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer melden"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diesen Nutzer melden? Wir prüfen die Anfrage und werden ggf. diesen Nutzer einschränken."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden"])}
  }
}