export default {
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your PawPal!"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lofin"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new account"])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile"])},
    "form": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Adress"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
      "upload_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "remove_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title (i.e. \"Lisa and Bello\")"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discribe yourself in you own words"])},
      "characteristic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characteristics"])},
      "searchingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
    }
  },
  "characteristics": {
    "cuddly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuddly"])},
    "playful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["playful"])},
    "intelligent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intelligent"])}
  },
  "searchingFor": {
    "paw_pal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paw pal"])},
    "dog_walker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dog walker"])},
    "dog_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dog owner"])}
  }
}